import { useEffect, useState } from "react";
import axios from "axios";
import { DefaultLayout, PageName } from "../Certificate/CertificateStyles";

function LoginComponent() {
  const [userId, setUserId] = useState("193712345");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) window.location.href = "/";
  });

  const handleLogin = async (e: any) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
        schoolId: userId,
        password: password,
      });

      if (response.data.data.id === 1) {
        localStorage.setItem("accessToken", JSON.stringify(response.data.data.accessToken));
        window.location.href = "/";
      } else {
        alert("관리자 계정으로 로그인 해주세요.");
      }
    } catch (error: any) {
      if (error.response.data.data.code === 4005) {
        alert(`${error.response.data.data.message}`);
      } else if (error.response.status === 400) {
        alert("학번 및 비밀번호를 다시 확인해주세요!");
      }
      console.error(error);
    }
  };

  return (
    <DefaultLayout>
      <br />
      <PageName>SMUS 관리 페이지</PageName>
      <br />
      <div style={{ border: "1.5px solid gray", width: "100%" }} />
      <br />
      <form onSubmit={handleLogin}>
        <div>
          <label typeof="userId">ID: </label>
          <br />
          <input
            type="text"
            id="userId"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            required
          />
        </div>
        <br />
        <div>
          <label typeof="password">Password: </label>
          <br />
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <br />
        <button type="submit">Login</button>
      </form>
    </DefaultLayout>
  );
}

export default LoginComponent;
