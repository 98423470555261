import styled from "styled-components";
import Select from "react-select";
export const CertificateDetailLayout = styled.div``;

export const CertificateDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5em;
  @media (max-width: 780px) {
    padding: 0;
  }
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const CertificateImageContainer = styled.div`
  flex: 1;
  margin: 0 1em;
`;

export const CertificateButtonContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  margin: 0 1em;
`;

export const CeritificationButtonLayout = styled.div`
  margin: 0.3em 0;
  background: #2b59cc;
  border-radius: 5px;
  width: 100%;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const CertificationButtonLabel = styled.p`
  color: white;
`;

export const CerfitificationImage = styled.img`
  flex: 1;
  background-size: contain;
  width: 100%;
  border: 1px solid black;
`;

export const MajorSelect = styled(Select)`
  @media (min-width: 500px) {
    width: 15em;
  }
`;

export const CertificationDescription = styled.div`
  width: 90%;
  padding: 5%;
  border: 1px black solid;
`;
export const RejectInputModalLayout = styled.div`
  width: 100%;
`;

export const CloseModalButtonLayout = styled.div`
  padding: 0.5rem 0;
`;

export const RejectTextInputLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

export const RejectTextInput = styled.textarea`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: solid 1px;
  height: 10rem;
`;
