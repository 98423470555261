import React from "react";
import "./App.css";
import Certificate from "./pages/Certificate";
import { Routes, Route, Router } from "react-router-dom";
import CertificateDetail from "./pages/CertificateDetail";
import LoginComponent from "./pages/login/Login";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Certificate />}></Route>
        <Route path="/login" element={<LoginComponent />}></Route>
        <Route path="/certificate/:reportId" element={<CertificateDetail />}></Route>
      </Routes>
    </div>
  );
}

export default App;
