import axios from "axios";
import { useEffect, useState } from "react";
import {
  CertificateLayout,
  CertificateElementLayout,
  CertificateButtonLabel,
  CertificateButtonLayout,
  CertificateUserId,
  CertificationElementListLayout,
  PageName,
} from "./CertificateStyles";

const Certificate = () => {
  const [certificateList, setCertificateList] = useState<
    { id: number; imgUrl: string; description: string; userId: number }[]
  >([]);

  const setUserIdListFromServer = (accessToken: string) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/certificate/list`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(accessToken)}`,
        },
      })
      .then((response) => {
        const userMajorList: { id: number; imgUrl: string; description: string; userId: number }[] =
          [];
        response.data.data.map(
          (element: { id: number; imgUrl: string; description: string; userId: number }) => {
            userMajorList.push({
              id: element.id,
              userId: element.userId,
              description: element.description,
              imgUrl: element.imgUrl,
            });
          }
        );
        return setCertificateList(userMajorList);
      })
      .catch((response) => {
        console.error(response);
        return setCertificateList([]);
      });
  };

  useEffect(() => {
    const accesstoken = localStorage.getItem("accessToken");
    if (accesstoken === null) {
      window.location.href = "/login";
    } else {
      setUserIdListFromServer(accesstoken);
    }
  }, []);
  return (
    <CertificateLayout>
      <PageName>학과 인증 리스트</PageName>
      <br />

      <button
        onClick={() => {
          localStorage.clear();
          window.location.href = "/";
        }}
      >
        로그아웃(인증 에러가 뜰 때)
      </button>
      <br />
      <CertificationElementListLayout>
        {certificateList.length !== 0 ? (
          certificateList.map((element) => (
            <div key={element.id}>
              <CertificateElementLayout>
                <CertificateUserId>{element.userId} 유저 학과 인증요청</CertificateUserId>
                <CertificateButtonLayout to={`certificate/${element.id}`}>
                  <CertificateButtonLabel>보기</CertificateButtonLabel>
                </CertificateButtonLayout>
              </CertificateElementLayout>
              <br />
            </div>
          ))
        ) : (
          <span>현재 요청이 없습니다.</span>
        )}
      </CertificationElementListLayout>
    </CertificateLayout>
  );
};

export default Certificate;
