import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CertificateDetailLayout,
  CertificateDetailContainer,
  CertificateImageContainer,
  CertificateButtonContainer,
  CeritificationButtonLayout,
  CertificationButtonLabel,
  CerfitificationImage,
  MajorSelect,
  CertificationDescription,
  CloseModalButtonLayout,
  RejectInputModalLayout,
  RejectTextInput,
  RejectTextInputLayout,
} from "./CertificateDetailStyles";
import Modal from "react-modal";
import makeAnimated from "react-select/animated";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import { PageName } from "../Certificate/CertificateStyles";

const CertificateDetail = () => {
  const params = useParams();
  const reportId = params.reportId ? parseInt(params.reportId) : -1;
  const [userId, setUserId] = useState<number>(-1);
  const [image, setImage] = useState<string>("");
  const [description, setDescription] = useState<string>("설명 없음.");
  const [userMajorIds, setUserMajorIds] = useState<number[]>([]);
  const [isShowRejectModal, setIsShowRejectModal] = useState<boolean>(false);
  const [rejectText, setRejectText] = useState<string | null>(null);
  const [accessToken, setAccessToken] = useState<any>();
  const animatedComponents = makeAnimated();

  const getCertDetail = (reportId: number, accessToken: string) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/certificate/info/${reportId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setUserId(response.data.data.userId);
        setImage(response.data.data.imgUrl);
        setDescription(response.data.data.description);
        return response.data.data;
      })
      .catch((error) => {
        console.log(error.response.data);
        alert(error.response.data.message);
        window.location.href = "/";
      });
  };

  const confirm = (reportId: number, majorIds: number[]) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/certificate/update`,
        {
          id: reportId,
          majorIds: majorIds,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.code === 200) {
          alert("수락되었습니다.");
          window.location.href = "/";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const reject = () => {
    if (rejectText != null) {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/api/certificate/reject`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          data: JSON.stringify({
            id: reportId,
            rejectText: rejectText,
          }),
        })
        .then((response) => {
          console.log(response);
          if (response.data.code === 200) {
            alert("거절이 완료되었습니다.");
            window.location.href = "/";
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      alert("거절 사유를 적어주세요.");
    }
  };

  useEffect(() => {
    let accessToken: any = localStorage.getItem("accessToken");
    const aceessToken = JSON.parse(accessToken);
    if (accessToken === null) {
      window.location.href = "/";
    }
    setAccessToken(aceessToken);
    getCertDetail(reportId, aceessToken);
  }, []);

  return (
    <CertificateDetailLayout>
      <PageName>
        {"<"} User ID: {userId} - Report ID: {reportId} {">"}
      </PageName>
      <br />
      <br />
      <CertificateDetailContainer>
        <CertificateImageContainer>
          <CerfitificationImage src={image} />
        </CertificateImageContainer>
        <CertificateButtonContainer>
          <CeritificationButtonLayout
            onClick={() => {
              setIsShowRejectModal(true);
            }}
          >
            <CertificationButtonLabel>거절: 사유 작성</CertificationButtonLabel>
          </CeritificationButtonLayout>
          <br />
          <CertificationDescription>
            <span style={{ color: "gray", fontWeight: 1000 }}>선택한 전공:</span>
            <br />
            <span style={{ fontSize: "20px" }}>{description}</span>
          </CertificationDescription>{" "}
          <br />
          <br />
          <div style={{ border: "1.5px solid gray", width: "100%" }} />
          <br />
          <MajorSelect
            onChange={(e: any) => {
              const majorList: number[] = [];
              e.map((element: { value: number }) => {
                majorList.push(element.value);
                setUserMajorIds(majorList);
              });
            }}
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={majorOptions}
            placeholder="학과 선택(복수 가능)"
          />
          <CeritificationButtonLayout
            onClick={() => {
              confirm(reportId, userMajorIds);
            }}
          >
            <CertificationButtonLabel>인증수락</CertificationButtonLabel>
          </CeritificationButtonLayout>
        </CertificateButtonContainer>
      </CertificateDetailContainer>

      <Modal isOpen={isShowRejectModal}>
        <RejectInputModalLayout>
          <CloseModalButtonLayout onClick={() => setIsShowRejectModal(!isShowRejectModal)}>
            <AiOutlineClose size={20} />
          </CloseModalButtonLayout>
          <span>거절 사유 입력:</span>
          <RejectTextInputLayout>
            <RejectTextInput
              onChange={(e) => {
                setRejectText(e.target.value);
              }}
            />
            <CeritificationButtonLayout
              onClick={() => {
                reject();
              }}
            >
              <CertificationButtonLabel>인증 거절</CertificationButtonLabel>
            </CeritificationButtonLayout>
          </RejectTextInputLayout>
        </RejectInputModalLayout>
      </Modal>
    </CertificateDetailLayout>
  );
};

const majorOptions = [
  { value: 2, label: "컴퓨터과학과" },
  { value: 3, label: "휴먼지능정보공학전공" },
  { value: 4, label: "경제금융학부" },
  { value: 5, label: "역사콘텐츠전공" },
  { value: 6, label: "지적재산권전공" },
  { value: 7, label: "문헌정보학전공" },
  { value: 8, label: "공간환경학부" },
  { value: 9, label: "행정학부" },
  { value: 10, label: "가족복지학과" },
  { value: 11, label: "국가안보학과" },
  { value: 12, label: "국어교육과" },
  { value: 13, label: "영어교육과" },
  { value: 14, label: "교육학과" },
  { value: 15, label: "수학교육과" },
  { value: 16, label: "경영학부" },
  { value: 17, label: "글로벌경영학과" },
  { value: 18, label: "융합경영학과" },
  { value: 19, label: "핀테크·빅데이터융합·스마트생산 전공" },
  { value: 20, label: "전기공학전공" },
  { value: 21, label: "지능IOT융합전공" },
  { value: 22, label: "게임전공" },
  { value: 23, label: "애니메이션전공" },
  { value: 24, label: "한일문화콘텐츠전공" },
  { value: 25, label: "생명공학전공" },
  { value: 26, label: "화학에너지공학전공" },
  { value: 27, label: "화공신소재전공" },
  { value: 28, label: "식품영양학전공" },
  { value: 29, label: "의류학전공" },
  { value: 30, label: "스포츠건강관리전공" },
  { value: 31, label: "무용예술전공" },
  { value: 32, label: "조형예술전공" },
  { value: 33, label: "생활예술전공" },
  { value: 34, label: "음악학부" },
];

export default CertificateDetail;
