import { Link } from "react-router-dom";
import styled from "styled-components";

export const DefaultLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 4em;
`;

export const CertificateLayout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 2em;
  @media (min-width: 1000px) {
    padding: 0 15em;
  }
`;

export const CertificateElementLayout = styled.div`
  height: 4em;
  padding: 0 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: solid 0.5px gray;
  border-radius: 5px;
`;

export const CertificateUserId = styled.p`
  font-size: 1rem;
  @media (max-width: 500px) {
    font-size: 0.7rem;
  }
`;

export const CertificateButtonLayout = styled(Link)`
  padding: 0 1em;
  background: #2b59cc;
  height: 2.5em;
  border-radius: 5px;
  display: flex;
  align-items: center;
  text-decoration: none;
  @media (max-width: 500px) {
    font-size: 0.5rem;
  }
`;

export const CertificateButtonLabel = styled.p`
  font-size: 1rem;
  color: white;
  @media (max-width: 500px) {
    font-size: 0.5rem;
  }
`;

export const CertificationElementListLayout = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 500px) {
    font-size: 0.5rem;
  }
`;
export const PageName = styled.span`
  font-size: 1.5rem;
  flex-direction: column;
  color: #2b59cc;
  font-weight: 700;
  @media (max-width: 500px) {
    font-size: 0.8rem;
  }
`;
